import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { refreshToken } from '@store/auth/auth.actions';
import {
  actions as basketEditOrderActions,
  selectors as basketEditOrderSelectors,
} from '@store/basket/basketEditOrder.slice';
import {
  actions as basketNewOrderActions,
  selectors as basketNewOrderSelectors,
} from '@store/basket/basketNewOrder.slice';
import { selectDisableTracking } from '@store/nativeAppConfig/nativeAppConfig.slice';
import {
  useIsFetching,
  useIsMutating,
  useQueryClient,
} from '@tanstack/react-query';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';

import { getBasketPrices } from '@components/modules/Basket/BasketStatic/useBasketPrices';
import BASKET_MODES from '@constants/basketModes';
import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import DIET_LENGTH_MODE from '@constants/dietLengtMode';
import ERROR_TYPES from '@constants/errorTypes';
import ROUTE_URLS from '@constants/routeUrls';
import TAB_SLUGS from '@constants/tabSlugs';
import {
  changeStepAndResetValid,
  selectOrderTabsPick,
  selectTabs,
} from '@features/orderTabs/orderTabsSlice';
import useDefaultDietElement from '@hooks/basket/useDefaultDietElement';
import useStaticBasketStore from '@hooks/basket/useStaticBasketStore';
import useTransformResponseBasketRedux from '@hooks/basket/useTransformResponseBasketRedux';
import useValidateBasket from '@hooks/basket/useValidateBasket';
import {
  selectBranding,
  selectMultinational,
  useAppConfigSelector,
  useAppMode,
} from '@hooks/useAppConfigSelectors';
import useBasket from '@hooks/useBasket';
import useBasketClean from '@hooks/useBasketClean';
import useBasketClear from '@hooks/useBasketClear';
import useBasketModifyDay from '@hooks/useBasketModifyDay';
import useCreateBasket from '@hooks/useCreateBasket';
import useCreateBasketAdditionalMealType from '@hooks/useCreateBasketAdditionalDishes';
import useCreateBasketAddon from '@hooks/useCreateBasketAddon';
import useCreateBasketDiet from '@hooks/useCreateBasketDiet';
import useCreateBasketDish from '@hooks/useCreateBasketDish';
import useDuplicateBasketDiet from '@hooks/useDuplicateBasketDiet';
import useOrderForm from '@hooks/useOrderForm';
import useUpdateAdditionalMealTypes from '@hooks/useUpdateAdditionalMealTypes';
import useUpdateBasketModify from '@hooks/useUpdateBasketModify';
import useValidateDiscountCode from '@hooks/useValidateDiscountCode';
import { pushDiscountCodeUpdateGTMEvent } from '@utils/gtm';
import { isClient, isEmptyObject, isFalsify, sortDates } from '@utils/helpers';
import showToast from '@utils/showToast';

import useSyncGtmCart from './hooks/useSyncGtmCart';

export const BasketMethodsContext = createContext([{}, () => {}]);

const BasketMethodsProvider = ({ basketKey = 'basketNewOrder', children }) => {
  const { t } = useTranslation();

  const router = useRouter();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const syncGtmCart = useSyncGtmCart();

  const [failureCount, setFailureCount] = useState(0);
  const [showAbandonedModal, setShowAbandonedModal] = useState(true);

  const [
    isBasketNewOrderPaymentSummaryModalOpen,
    setIsBasketNewOrderPaymentSummaryModalOpen,
  ] = useState(false);

  const [
    isBasketEditOrderPaymentSummaryModalOpen,
    setIsBasketEditOrderPaymentSummaryModalOpen,
  ] = useState(false);

  const [isCompletedCreateBasketParams, setIsCompletedCreateBasketParams] =
    useState(null);

  // transforming basket by params beetwen static and normal
  const [transformBasketStatus, setTransformBasketStatus] = useState(null);

  const [shouldScrollIntoVariants, setShouldScrollIntoVariants] =
    useState(false);

  const basketQuery = useBasket({ basketKey });

  const updateBasketModifyQuery = useUpdateBasketModify({ basketKey });
  const mutateDietQuery = useCreateBasketDiet({ basketKey });
  const mutateDuplicateDietQuery = useDuplicateBasketDiet({ basketKey });

  const { mutateAsync: createBasket } = useCreateBasket({ basketKey });
  const { mutateAsync: basketCleanUp } = useBasketClean({ basketKey });
  const { mutateAsync: mutateBasketDiet } = mutateDietQuery;
  const { mutateAsync: mutateBasketDietDuplicate } = mutateDuplicateDietQuery;
  const { mutateAsync: mutateAdditionalMealTypes } =
    useUpdateAdditionalMealTypes({
      basketKey,
    });
  const { mutateAsync: updateBasketDish } = useCreateBasketDish({ basketKey });
  const { mutateAsync: updateBasketMealType } =
    useCreateBasketAdditionalMealType({ basketKey });
  const { mutateAsync: basketClear } = useBasketClear({
    basketKey,
    options: {
      onSuccess: () => dispatch(clearBasket()),
    },
  });

  const { mutateAsync: updateBasketAddon } = useCreateBasketAddon({
    basketKey,
  });

  const { mutateAsync: updateBasketModifyDay } = useBasketModifyDay({
    basketKey,
  });

  const validateDiscountCodeQuery = useValidateDiscountCode();
  const defaultDietElement = useDefaultDietElement();
  const [transformResponseBasketRedux] = useTransformResponseBasketRedux();
  const { mutateAsync: updateBasketModify } = updateBasketModifyQuery;
  const { mutateAsync: updateDiscountCode } = validateDiscountCodeQuery;

  const stateBasket = {
    basketNewOrder: {
      isPaymentSummaryModalOpen: isBasketNewOrderPaymentSummaryModalOpen,
      openPaymentSummaryModal: () =>
        setIsBasketNewOrderPaymentSummaryModalOpen(true),
      closePaymentSummaryModal: () =>
        setIsBasketNewOrderPaymentSummaryModalOpen(false),
    },
    basketEditOrder: {
      isPaymentSummaryModalOpen: isBasketEditOrderPaymentSummaryModalOpen,
      openPaymentSummaryModal: () =>
        setIsBasketEditOrderPaymentSummaryModalOpen(true),
      closePaymentSummaryModal: () =>
        setIsBasketEditOrderPaymentSummaryModalOpen(false),
    },
  }[basketKey];

  const { isOnlyShop, isDietShop, isDiet } = useAppMode();
  const { name: brandName } = useAppConfigSelector(selectBranding);
  const {
    defaultRegion: { currencyCode },
  } = useAppConfigSelector(selectMultinational);

  const { isBasketNewOrder, isBasketEditOrder } = useMemo(
    () => ({
      isBasketNewOrder: basketKey === 'basketNewOrder',
      isBasketEditOrder: basketKey === 'basketEditOrder',
    }),
    [basketKey]
  );

  const { isEmptyBasket } = useMemo(
    () => ({
      isEmptyBasket: isEmpty(basketQuery.data?.rows),
    }),

    [basketQuery.dataUpdatedAt, basketQuery.errorUpdatedAt]
  );

  const prevIsEmptyBasket = usePrevious(isEmptyBasket);

  const isMutatingBasketClean = useIsMutating({
    mutationKey: [`${basketKey}Clean`],
  });
  const isMutatingBasketClear = useIsMutating({
    mutationKey: [`${basketKey}Clear`],
  });
  const isMutatingBasketAddModifyDay = useIsMutating({
    mutationKey: [`${basketKey}AddModifyDay`],
  });
  const isMutatingBasketCreate = useIsMutating({
    mutationKey: [`${basketKey}Create`],
  });
  const isMutatingBasketAddAddon = useIsMutating({
    mutationKey: [`${basketKey}AddAddon`],
  });
  const isMutatingBasketAddDiet = useIsMutating({
    mutationKey: [`${basketKey}AddDiet`],
  });
  const isMutatingBasketAddDish = useIsMutating({
    mutationKey: [`${basketKey}AddDish`],
  });
  const isMutatingBasketRestore = useIsMutating({
    mutationKey: [`${basketKey}Restore`],
  });
  const isMutatingBasketUpdateModify = useIsMutating({
    mutationKey: [`${basketKey}UpdateModify`],
  });
  const isMutatingBasketUpdatingAdditionalMealTypes = useIsMutating({
    mutationKey: [`${basketKey}UpdateAdditionalMealTypes`],
  });

  const isMutatingBasket = [
    isMutatingBasketClean,
    isMutatingBasketClear,
    isMutatingBasketAddModifyDay,
    isMutatingBasketCreate,
    isMutatingBasketAddAddon,
    isMutatingBasketAddDiet,
    isMutatingBasketAddDish,
    isMutatingBasketRestore,
    isMutatingBasketUpdateModify,
    isMutatingBasketUpdatingAdditionalMealTypes,
  ].some(Boolean);

  const isFetchingBasketClean = useIsFetching({
    queryKey: [`${basketKey}Clean`],
  });
  const isFetchingBasketClear = useIsFetching({
    queryKey: [`${basketKey}Clear`],
  });
  const isFetchingBasketAddModifyDay = useIsFetching({
    queryKey: [`${basketKey}AddModifyDay`],
  });
  const isFetchingBasketCreate = useIsFetching({
    queryKey: [`${basketKey}Create`],
  });
  const isFetchingBasketAddAddon = useIsFetching({
    queryKey: [`${basketKey}AddAddon`],
  });
  const isFetchingBasketAddDiet = useIsFetching({
    queryKey: [`${basketKey}AddDiet`],
  });
  const isFetchingBasketAddDish = useIsFetching({
    queryKey: [`${basketKey}AddDish`],
  });
  const isFetchingBasketRestore = useIsFetching({
    queryKey: [`${basketKey}Restore`],
  });
  const isFetchingBasketUpdateModify = useIsFetching({
    queryKey: [`${basketKey}UpdateModify`],
  });

  const isFetchingBasket = [
    isFetchingBasketClean,
    isFetchingBasketClear,
    isFetchingBasketAddModifyDay,
    isFetchingBasketCreate,
    isFetchingBasketAddAddon,
    isFetchingBasketAddDiet,
    isFetchingBasketAddDish,
    isFetchingBasketRestore,
    isFetchingBasketUpdateModify,
  ].some(Boolean);

  const basketActions = isBasketNewOrder
    ? basketNewOrderActions
    : basketEditOrderActions;

  const basketSelectors = isBasketNewOrder
    ? basketNewOrderSelectors
    : basketEditOrderSelectors;

  const disableTracking = useSelector(selectDisableTracking);
  const { isNextDisabled, currentStepIndex } = useSelector(selectTabs);
  const basketStore = useSelector(basketSelectors.selectBasket);

  const currentDietNewOrder = useSelector(
    basketNewOrderSelectors.selectCurrentDiet,
    shallowEqual
  );
  const currentDietEditOrder = useSelector(
    basketEditOrderSelectors.selectCurrentDiet,
    shallowEqual
  );

  const currentDiet = isBasketNewOrder
    ? currentDietNewOrder
    : currentDietEditOrder;

  const currentStepSlug = useSelector(state =>
    selectOrderTabsPick(state, ['currentStepSlug'])
  );

  const {
    addDiet: addDietBasket,
    addItemToBasket,
    setAdditionalMealTypes,
    updateAdditionalMealTypes,
    removeDiet: removeDietBasket,
    setDiscountCode,
    updateDiet: updateDietBasket,
    updateBasketWithCurrentDiet,
    clearBasket,
    setBasketValidity,
    setStoreFromQuery,
    setBasketMode,
    setActiveOrderDietIri,
  } = basketActions;

  const {
    discountCode,
    isPayActionSuccess,
    items: basketItems,
    useDiscountCode,
    basketMode,
  } = basketStore;

  const dietElements = basketItems.dietElements;
  const firstDietElement = dietElements[0];

  const isBasketStatic = basketMode === BASKET_MODES.STATIC;
  const basketQueryId = basketQuery?.data?.id;
  const hostname = isClient
    ? `${window.location.protocol}//${window.location.host}`
    : '';

  const shareBasket = useCallback(
    async basketId => {
      const shareUrl = `${hostname}${ROUTE_URLS.NEW_ORDER_FORM}?basketId=${
        basketId || basketQueryId
      }`;

      await navigator.clipboard.writeText(shareUrl);
      showToast(t('$*common.copyToClipboard.copied'), {
        type: 'info',
      });

      createBasket({ basketId: basketId || basketQueryId }).then(response => {
        const transformedResponse = transformResponseBasketRedux(
          response,
          basketItems
        );
        dispatch(setStoreFromQuery(transformedResponse));
        dispatch(setBasketValidity(checkIsValid(response.uiRows)));

        router.replace(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              basketId: response?.id,
            },
          },
          undefined,
          { shallow: true }
        );
      });
    },
    [basketQueryId, createBasket, hostname, router?.pathname]
  );

  const basketCommonCatch = useCallback(
    ({
      data = {},
      scopeError = '',
      passedBasketQueryId,
      isInitializingError = false,
    } = {}) => {
      const violations = data?.violations ?? [];
      if (isEmpty(violations)) return;
      const hasErrorTestMode = some(violations, { propertyPath: 'testMode' });
      const hasErrorDiscountCode = some(violations, {
        propertyPath: 'discountCode.minimalOrderValue',
      });
      // max orders on day
      const errorCerberDays = violations.filter(
        ({ propertyPath }) => propertyPath.includes('deliveryDay') // cerber from create-order
      );
      const hasErrorCerber = errorCerberDays.length > 0;

      if (!isInitializingError && hasErrorCerber) {
        const message = data?.['hydra:description'] ?? '';
        const errorDays = [...message.match(/\d{4}-\d{2}-\d{2}/gm)];

        refetchOrderForm();

        // check error is on the same step
        if (
          !(
            isDietShop &&
            scopeError === 'updateBasketDish' &&
            currentStepSlug === TAB_SLUGS.SELECT_DISHES
          )
        ) {
          dispatch(changeStepAndResetValid(0));
        }

        const id = passedBasketQueryId ?? basketQueryId;

        if (id) {
          basketCleanUp({ id }).then(response => {
            const transformedResponse = transformResponseBasketRedux(
              response,
              basketItems
            );
            dispatch(setStoreFromQuery(transformedResponse));
          });
        } else {
          createBasket();
        }

        return showToast(
          t('$*notification.basket.cerberReset.error', {
            days: errorDays.join(', '),
          }),
          { type: 'error', toastId: 'cerberResetInfo' }
        );
      }

      if (hasErrorTestMode) {
        dispatch(changeStepAndResetValid(0));
      }

      if (hasErrorDiscountCode) {
        dispatch(
          setDiscountCode({
            useDiscountCode: false,
            discountCode: { '@id': null, value: '' },
          })
        );
        queryClient.setQueryData('discountCode', {});
      }

      if (isInitializingError) {
        mutateBasketDiet({
          basket: passedBasketQueryId ?? basketQueryId,
          package: defaultDietElement.packageId,
          diet: defaultDietElement.dietId,
          variant: defaultDietElement.variantId,
          calorific: defaultDietElement.calorificId,
          paymentMode: defaultDietElement.paymentMode,
          dietLength: defaultDietElement.dietLength,
          deliveryDates: defaultDietElement.deliveryDates,
          firstDeliveryDay: defaultDietElement.firstDeliveryDay,
          useEcoContainers: defaultDietElement.useEcoContainers,
          addons: defaultDietElement.addons,
          optionChangeMenu: defaultDietElement.optionChangeMenu,
          saturdayInclude: defaultDietElement.saturdayInclude,
          sundayInclude: defaultDietElement.sundayInclude,
          allowStepWithAdditionalMealTypes:
            defaultDietElement.allowStepWithAdditionalMealTypes,
          hasDietsToSelectMenu: defaultDietElement?.hasDietsToSelectMenu,
        }).then(response => {
          setFailureCount(0);
          const firstResponseDiet = getNewDietFromBasketResponse(response);

          dispatch(setBasketMode(BASKET_MODES.NORMAL));

          dispatch(
            updateDietBasket({
              '@id': firstResponseDiet?.['@id'],
              '@type': firstResponseDiet?.['@type'],
              ...defaultDietElement,
            })
          );
          dispatch(setBasketValidity(checkIsValid(response.uiRows)));
          dispatch(changeStepAndResetValid(0));
        });
      }

      return violations.map(({ message, propertyPath }) => {
        showToast(message, { type: 'error', toastId: propertyPath });
      });
    },
    [basketQueryId]
  );

  const basketStoreDietIris =
    basketStore.items.dietElements?.reduce((acc, { dietId }) => {
      return [...acc, dietId];
    }, []) ?? [];
  const basketQueryDietIris = basketQuery?.data?.rows?.reduce((acc, item) => {
    return [...acc, item?.diet?.['@id']];
  }, []);

  const isAnyOfDietsInBasket = useCallback(
    (dietIds = []) => {
      const dietElements = isBasketStatic
        ? basketStoreDietIris
        : basketQueryDietIris;

      if (!Array.isArray(dietIds) || !Array.isArray(dietElements)) {
        return false;
      }

      return dietElements?.some(dietIri => dietIds.includes(dietIri));
    },
    [
      basketQuery.dataUpdatedAt,
      basketQuery.errorUpdatedAt,
      basketQueryDietIris,
      basketStoreDietIris,
      isBasketStatic,
    ]
  );

  const discountIri = discountCode?.['@id'];
  const updateOrderBasketModify = useCallback(
    async ({
      basketId = null,
      payload,
      newDiscountCode,
      onSuccessCallback,
    }) => {
      const isResetPayload = isEmptyObject(payload);
      const mergePayload = isResetPayload
        ? {}
        : {
            ...(useDiscountCode ? { discountCode: discountIri } : {}),
            ...payload,
          };

      return updateBasketModify({
        id: basketId || basketQueryId,
        payload: mergePayload,
      })
        .then(res => {
          if (onSuccessCallback) {
            onSuccessCallback();
          }

          if ('discountCode' in payload && !disableTracking) {
            pushDiscountCodeUpdateGTMEvent({
              discountCode: newDiscountCode,
              items: res?.rows,
              price: res?.price?.afterDiscount,
              brandName,
              currencyCode,
            });

            showToast(
              t(
                '$*notification.validateDiscountCode.success',
                'Rabat został pomyślnie wczytany'
              ),
              { type: 'success' }
            );
          }

          if ('abandoned' in payload) {
            dispatch(refreshToken());
          }

          if (!isEmpty(res.notifications)) {
            Object.values(res.notifications).forEach(({ message, type }) => {
              showToast(message, { type: type.toLowerCase() });
            });
          }
        })
        .catch(error => {
          basketCommonCatch({ data: error?.response?.data });
        });
    },
    [
      basketQueryId,
      discountIri,
      useDiscountCode,
      disableTracking,
      basketCommonCatch,
    ]
  );

  const commonSetDiscountCode = useCallback(
    async code => {
      const newCode = code.replace(/  +/g, ' ')?.trim();

      return updateDiscountCode({
        code: newCode,
        basket: basketQuery?.data?.['@id'],
      })
        .then(async response => {
          if (
            !isEmpty(response?.diets) &&
            !isAnyOfDietsInBasket(response?.diets)
          ) {
            return showToast(
              t(
                '$*notification.validateDiscountCode.noDietsErrorMessage',
                'Code does not work for ordered diet types'
              )
            );
          }

          dispatch(
            setDiscountCode({
              useDiscountCode: true,
              discountCode: { '@id': response['@id'], value: newCode },
            })
          );

          let basketId;
          if (isBasketStatic) {
            const response = await createBasketFromStaticBasket();
            basketId = response.id;
          }

          updateOrderBasketModify({
            newDiscountCode: newCode,
            basketId,
            payload: {
              discountCode: response['@id'],
            },
          });
        })
        .catch(error => {
          throw error;
        });
    },
    [
      updateOrderBasketModify,
      isAnyOfDietsInBasket,
      updateDiscountCode,
      isBasketStatic,
      firstDietElement,
    ]
  );

  // check code after login, when is error remove from basket
  const recheckDiscountCode = useCallback(
    async code => {
      const newCode = code.replace(/  +/g, ' ')?.trim();

      return updateDiscountCode({ code: newCode }).catch(error => {
        const violations = error?.response?.data?.violations;
        violations.map(({ propertyPath, message }, index) => {
          showToast(message, { toastId: `${propertyPath}_${index}` });
        });

        dispatch(
          setDiscountCode({
            useDiscountCode: false,
            discountCode: { '@id': null, value: '' },
          })
        );

        updateOrderBasketModify({
          payload: {},
        });
      });
    },
    [updateDiscountCode, updateOrderBasketModify]
  );

  const checkBasketIsValidToPayment = useCallback(() => {
    if (
      isMutatingBasket ||
      isEmptyBasket ||
      (isBasketNewOrder && isNextDisabled)
    ) {
      return false;
    }

    return true;
  }, [isMutatingBasket, isEmptyBasket, isBasketNewOrder, isNextDisabled]);

  useEffect(() => {
    if (isBasketNewOrder) {
      if (
        prevIsEmptyBasket === false &&
        isEmptyBasket &&
        currentStepIndex !== 0
      ) {
        dispatch(changeStepAndResetValid(0));
        dispatch(basketNewOrderActions.resetBasket());

        showToast(t('$*notification.basketEmptyInvalid.error'), {
          type: 'warning',
        });
      }
    }
  }, [isEmptyBasket, prevIsEmptyBasket, currentStepIndex, isBasketNewOrder]);

  const [checkIsValid] = useValidateBasket();

  const basketActiveOrderDietIri = basketStore.activeOrderDietIri;

  const getBasketQueryData = () => {
    return queryClient.getQueryData([basketKey]);
  };

  const handleChangeProductQuantity = useCallback(
    async ({ dishIri, day, quantity, dietIri = 'noDiet', connectedToIri }) => {
      let basketIri, firstDietIri;
      if (isBasketStatic) {
        const response = await createBasketFromStaticBasket();
        basketIri = response['@id'];
        firstDietIri = response.rows[0]['@id'];
      }

      const basket = getBasketQueryData();

      dispatch(
        addItemToBasket({
          item: { day, dietIri, iri: dishIri, quantity },
          options: {
            isOnlyShop,
            connectedTo:
              firstDietIri ?? connectedToIri ?? basketActiveOrderDietIri,
            basketKey,
          },
        })
      );

      const connectedTo =
        connectedToIri !== 'notConnected' ? connectedToIri : null;

      return updateBasketDish({
        shouldDebounce: true,
        basket: basketIri || basket['@id'],
        dishSize: dishIri,
        day,
        quantity,
        ...(isBasketNewOrder
          ? {
              connectedTo:
                firstDietIri ?? connectedTo ?? basketActiveOrderDietIri ?? null,
            }
          : {}),
        ...(dietIri !== 'noDiet' ? { clientDiet: dietIri } : {}),
      })
        .then(response => {
          syncGtmCart(basket, response);

          dispatch(setBasketValidity(checkIsValid(response.uiRows)));
          return true;
        })
        .catch(error => {
          basketCommonCatch({
            data: error?.response?.data,
            scopeError: 'updateBasketDish',
          });
        });
    },
    [
      basketActiveOrderDietIri,
      basketKey,
      brandName,
      // currencyCode,
      disableTracking,
      isBasketNewOrder,
      isOnlyShop,
      firstDietElement,
    ]
  );

  const handleChangeMealTypesQuantity = useCallback(
    async ({ dishIri, day, quantity, dietIri = 'noDiet', connectedToIri }) => {
      let basketIri, firstDietIri;
      if (isBasketStatic) {
        const response = await createBasketFromStaticBasket();
        basketIri = response['@id'];
        firstDietIri = response.rows[0]['@id'];
      }

      const basket = getBasketQueryData();

      dispatch(
        addItemToBasket({
          item: { day, dietIri, iri: dishIri, quantity },
          options: {
            isOnlyShop,
            connectedTo:
              firstDietIri ?? connectedToIri ?? basketActiveOrderDietIri,
            basketKey,
          },
        })
      );

      const connectedTo =
        connectedToIri !== 'notConnected' ? connectedToIri : null;

      return updateBasketMealType({
        basket: basketIri || basket['@id'],
        mealType: dishIri,
        day,
        quantity,
        ...(isBasketNewOrder
          ? {
              connectedTo:
                firstDietIri ?? connectedTo ?? basketActiveOrderDietIri ?? null,
            }
          : {}),
        ...(dietIri !== 'noDiet' ? { clientDiet: dietIri } : {}),
      })
        .then(response => {
          dispatch(setBasketValidity(checkIsValid(response.uiRows)));
        })
        .catch(error => {
          basketCommonCatch({
            data: error?.response?.data,
            scopeError: 'updateBasketDish',
          });
        });
    },
    [
      basketActiveOrderDietIri,
      basketKey,
      brandName,
      currencyCode,
      disableTracking,
      isBasketNewOrder,
      isOnlyShop,
      firstDietElement,
    ]
  );

  const productItems = useMemo(
    () =>
      isOnlyShop || isBasketEditOrder
        ? basketItems
        : currentDiet.connectedElements ?? {},
    [
      isOnlyShop,
      isBasketEditOrder,
      basketItems,
      JSON.stringify(currentDiet.connectedElements),
    ] // TO CHECK !!
  );

  // TO REMOVE
  const checkIsProductSelected = useCallback(
    ({ product, dietIri = 'noDiet', selectedDate }) => {
      const dayItems = productItems?.[dietIri]?.[selectedDate];

      if (!isEmpty(dayItems)) {
        const items = Object.keys(dayItems);
        const productHasDishSize = product.sizes.some(size =>
          items.includes(size['@id'])
        );

        return productHasDishSize;
      }

      return false;
    },
    [productItems]
  );

  const handleChangeAddonQuantity = useCallback(
    async ({ addonIri, day, quantity, dietIri = 'noDiet' }) => {
      const basket = getBasketQueryData();

      const previousQuantity =
        (basket?.rows ?? []).find(
          row =>
            row?.day === day &&
            row?.clientDiet === dietIri &&
            row?.addon?.['@id'] === addonIri
        )?.quantity ?? 0;

      dispatch(
        addItemToBasket({
          item: { day, dietIri, iri: addonIri, quantity },
          options: {
            isOnlyShop,
            connectedTo: basketActiveOrderDietIri,
            basketKey,
          },
        })
      );

      updateBasketAddon({
        shouldDebounce: true,
        basket: basket['@id'],
        addon: addonIri,
        day,
        quantity,
        ...(isBasketNewOrder
          ? { connectedTo: basketActiveOrderDietIri || null }
          : {}),
        ...(dietIri !== 'noDiet' ? { clientDiet: dietIri } : {}),
      })
        .then(response => {
          syncGtmCart(basket, response);

          dispatch(setBasketValidity(checkIsValid(response.uiRows)));
        })
        .catch(error => {
          if (error === ERROR_TYPES.STALE_PROMISE) {
            return;
          }

          dispatch(
            addItemToBasket({
              item: { day, dietIri, iri: addonIri, quantity: previousQuantity },
              options: {
                isOnlyShop,
                connectedTo: basketActiveOrderDietIri,
                basketKey,
              },
            })
          );
          basketCommonCatch({ data: error?.response?.data });
        });
    },
    [
      basketActiveOrderDietIri,
      basketKey,
      brandName,
      disableTracking,
      isBasketNewOrder,
      isOnlyShop,
    ]
  );

  // TO REMOVE
  const checkIsAddonSelected = useCallback(
    ({ addon, dietIri = 'noDiet', selectedDate }) => {
      const dayItems = basketItems?.[dietIri]?.[selectedDate];

      if (!isEmpty(dayItems)) {
        const items = Object.keys(dayItems);
        return items.includes(addon['@id']);
      }

      return false;
    },
    [basketItems]
  );

  const handleUpdateBasketModifyDay = useCallback(
    async payload => {
      const basket = getBasketQueryData();

      if (!basket || isEmpty(basket)) return;

      return updateBasketModifyDay({
        payload: {
          basket: basket['@id'],
          ...payload,
        },
      }).catch(error => {
        basketCommonCatch({ data: error?.response?.data });
      });
    },
    [basketCommonCatch, basketKey]
  );

  const basketStoreDiets = dietElements?.filter(
    row => row['@type'] === 'BasketItemDiet'
  );
  const basketStoreDietIds = basketStoreDiets.map(row => row['@id']);

  const handleRemoveBasketDiet = useCallback(
    async dietIri => {
      const basket = getBasketQueryData();

      if (!basket || isEmpty(basket)) {
        return;
      }

      const currentDiet = dietElements?.find(diet => diet['@id'] === dietIri);

      const basketDiet = {
        addons: currentDiet?.addons,
        calorific: currentDiet?.calorificId,
        deliveryDates: sortDates(currentDiet?.deliveryDates ?? []),
        diet: currentDiet?.dietId,
        dietLength: currentDiet?.dietLength,
        firstDeliveryDay: currentDiet?.firstDeliveryDay,
        optionChangeMenu: currentDiet?.optionChangeMenu,
        package: currentDiet?.packageId,
        paymentMode: currentDiet?.paymentMode,
        quantity: 0,
        saturdayInclude: currentDiet?.saturdayInclude,
        sundayInclude: currentDiet?.sundayInclude,
        useEcoContainers: currentDiet?.useEcoContainers,
        variant: currentDiet?.variantId,
        mealTypes: currentDiet?.mealTypes,
        allowStepWithAdditionalMealTypes:
          currentDiet?.allowStepWithAdditionalMealTypes,
        hasDietsToSelectMenu: currentDiet?.hasDietsToSelectMenu,
      };

      mutateBasketDiet({
        basket: basket['@id'],
        existingItem: dietIri ?? null,
        ...basketDiet,
      }).then(response => {
        syncGtmCart(basket, response, { asSeparateEvents: false });

        dispatch(setBasketValidity(checkIsValid(response.uiRows)));
        dispatch(removeDietBasket(dietIri));
      });
    },
    [basketKey, brandName, dietElements, disableTracking]
  );

  const getNewDietFromBasketResponse = useCallback(
    response => {
      const responseDiets = response?.rows?.filter(
        row => row['@type'] === 'BasketItemDiet'
      );

      const diffResponseDiets = responseDiets.filter(
        row => !basketStoreDietIds.includes(row['@id'])
      );

      const firstResponseDiet = diffResponseDiets?.[0] ?? {
        '@id': null,
        '@type': null,
      };

      return firstResponseDiet;
    },
    [basketStoreDietIds]
  );

  const handleAddBasketDiet = useCallback(
    async basketItem => {
      const basket = getBasketQueryData();

      if (!basket || isEmpty(basket)) {
        return;
      }

      return mutateBasketDiet({
        shouldDebounce: true,
        basket: basket?.['@id'],
        ...basketItem,
      })
        .then(response => {
          syncGtmCart(basket, response);

          setFailureCount(0);

          const firstResponseDiet = getNewDietFromBasketResponse(response);
          dispatch(
            addDietBasket({
              '@id': firstResponseDiet?.['@id'],
              '@type': firstResponseDiet?.['@type'],
              addons: basketItem?.addons,
              calorificId: basketItem?.calorific,
              deliveryDates: sortDates(basketItem?.deliveryDates ?? []),
              dietId: basketItem?.diet,
              dietLength: basketItem?.dietLength,
              dietLengthMode:
                basketItem?.dietLengthMode ?? DIET_LENGTH_MODE.CUSTOM,
              firstDeliveryDay: basketItem?.firstDeliveryDay,
              optionChangeMenu: basketItem?.optionChangeMenu,
              packageLineFilters: basketItem?.packageLineFilters,
              packageId: basketItem?.package,
              paymentMode: basketItem?.paymentMode,
              useEcoContainers: basketItem?.useEcoContainers,
              variantId: basketItem?.variant,
              sundayInclude: basketItem?.sundayInclude ?? true,
              saturdayInclude: basketItem?.saturdayInclude ?? true,
              deliveryType: basketItem?.deliveryType ?? 'ADDRESS',
              address: basketItem?.address ?? {},
              pickUpPoint: basketItem?.pickUpPoint ?? {},
              mealTypes: basketItem?.mealTypes ?? [],
              allowStepWithAdditionalMealTypes:
                basketItem?.allowStepWithAdditionalMealTypes,
              hasDietsToSelectMenu: basketItem?.hasDietsToSelectMenu,
            })
          );

          dispatch(setBasketValidity(checkIsValid(response.uiRows)));
        })
        .catch(error => {
          if (error === ERROR_TYPES.STALE_PROMISE) {
            return;
          }

          setFailureCount(failureCount + 1);

          const isCancelledError =
            error && Object.prototype.hasOwnProperty.call(error, 'silent');

          if (!isCancelledError) {
            basketCommonCatch({ data: error?.response?.data });
          }
        });
    },
    [
      basketCommonCatch,
      basketKey,
      brandName,
      disableTracking,
      failureCount,
      getNewDietFromBasketResponse,
    ]
  );

  const handleUpdateBasketDiet = useCallback(
    async ({
      existingItem = null,
      scrollIntoVariants = false,
      shouldDebounce = false,
      ...updatedProps
    } = {}) => {
      const basket = getBasketQueryData();

      const { diets = [] } = queryClient.getQueryData(['orderForm']);

      setShouldScrollIntoVariants(scrollIntoVariants);

      if (existingItem === null && isEmpty(basket?.rows) && !isBasketStatic) {
        return handleAddBasketDiet({
          ...defaultDietElement,
          ...updatedProps,
        });
      }

      const basketCurrentDiet = existingItem
        ? basketItems?.dietElements?.find(row => row['@id'] === existingItem)
        : currentDiet;

      const currentQueryDiet = diets.find(
        ({ '@id': iri }) => iri === basketCurrentDiet.dietId
      );
      const currentVariant = currentQueryDiet?.variants?.find(
        ({ '@id': iri }) => iri === basketCurrentDiet.variantId
      );
      const currentCalorific = currentVariant?.calories?.find(
        ({ '@id': iri }) => iri === basketCurrentDiet.calorificId
      );

      const previousDiet = {
        addons: basketCurrentDiet?.addons,
        calorific: basketCurrentDiet?.calorificId,
        calorificName: currentCalorific?.name,
        deliveryDates: sortDates(basketCurrentDiet?.deliveryDates ?? []),
        diet: basketCurrentDiet?.dietId,
        dietLength: basketCurrentDiet?.dietLength,
        firstDeliveryDay: basketCurrentDiet?.firstDeliveryDay,
        name: basketCurrentDiet?.name,
        optionChangeMenu: basketCurrentDiet?.optionChangeMenu,
        package: basketCurrentDiet?.packageId,
        packageLineFilters: basketCurrentDiet?.packageLineFilters,
        paymentMode: basketCurrentDiet?.paymentMode,
        useEcoContainers: basketCurrentDiet?.useEcoContainers,
        variant: basketCurrentDiet?.variantId,
        variantName: currentVariant?.name,
        sundayInclude: basketCurrentDiet?.sundayInclude,
        saturdayInclude: basketCurrentDiet?.saturdayInclude,
        delivery: basketCurrentDiet?.delivery,
        mealTypes: basketCurrentDiet?.mealTypes,
        allowStepWithAdditionalMealTypes:
          basketCurrentDiet?.allowStepWithAdditionalMealTypes,
        hasDietsToSelectMenu: basketCurrentDiet?.hasDietsToSelectMenu,
      };

      // clean object from undefined values
      const updatedDiet = pickBy(
        {
          ...previousDiet,
          ...updatedProps,
        },
        v => v !== undefined
      );

      if (isBasketStatic) {
        if (updatedProps.delivery) {
          return await createBasketFromStaticBasket({
            delivery: updatedProps.delivery,
          });
        }

        if (updatedProps.addons) {
          return await createBasketFromStaticBasket({
            addons: updatedProps.addons,
          });
        }

        const updatedQueryDiet = diets.find(
          ({ '@id': iri }) => iri === updatedDiet.diet
        );
        const updatedVariant = updatedQueryDiet?.variants?.find(
          ({ '@id': iri }) => iri === updatedDiet.variant
        );
        const updatedCalorific = updatedVariant?.calories?.find(
          ({ '@id': iri }) => iri === updatedDiet.calorific
        );

        const dietInReduxStoreFormat = {
          id: updatedQueryDiet?.id,
          addons: updatedDiet?.addons,
          calorificId: updatedDiet?.calorific,
          calorificName: updatedCalorific?.name,
          deliveryDates: sortDates(updatedDiet?.deliveryDates ?? []),
          dietId: updatedDiet?.diet,
          dietLength: updatedDiet?.dietLength,
          existingItem,
          firstDeliveryDay: updatedDiet?.firstDeliveryDay,
          name: updatedDiet?.name,
          optionChangeMenu: updatedDiet?.optionChangeMenu,
          packageId: updatedDiet?.package,
          packageLineFilters: updatedDiet?.packageLineFilters,
          paymentMode: updatedDiet?.paymentMode,
          useEcoContainers: updatedDiet?.useEcoContainers,
          variantId: updatedDiet?.variant,
          variantName: updatedVariant?.name,
          sundayInclude: updatedDiet?.sundayInclude ?? true,
          saturdayInclude: updatedDiet?.saturdayInclude ?? true,
          delivery: updatedDiet?.delivery,
          mealTypes: updatedDiet?.mealTypes,
          allowStepWithAdditionalMealTypes:
            updatedDiet?.allowStepWithAdditionalMealTypes,
          hasDietsToSelectMenu: updatedDiet?.hasDietsToSelectMenu,
        };

        const basketPrices = getBasketPrices({
          dietElements: [dietInReduxStoreFormat],
          testMode,
          queryClient,
        });

        return dispatch(
          updateDietBasket({
            ...dietInReduxStoreFormat,
            price: basketPrices?.price,
          })
        );
      }

      if (!basket || isEmpty(basket)) {
        return;
      }

      dispatch(
        updateDietBasket({
          addons: updatedDiet?.addons,
          calorificId: updatedDiet?.calorific,
          deliveryDates: sortDates(updatedDiet?.deliveryDates ?? []),
          dietId: updatedDiet?.diet,
          dietLength: updatedDiet?.dietLength,
          existingItem,
          firstDeliveryDay: updatedDiet?.firstDeliveryDay,
          name: updatedDiet?.name,
          optionChangeMenu: updatedDiet?.optionChangeMenu,
          packageId: updatedDiet?.package,
          packageLineFilters: updatedDiet?.packageLineFilters,
          paymentMode: updatedDiet?.paymentMode,
          useEcoContainers: updatedDiet?.useEcoContainers,
          variantId: updatedDiet?.variant,
          sundayInclude: updatedDiet?.sundayInclude ?? true,
          saturdayInclude: updatedDiet?.saturdayInclude ?? true,
          delivery: updatedDiet?.delivery,
          mealTypes: updatedDiet?.mealTypes,
          allowStepWithAdditionalMealTypes:
            updatedDiet?.allowStepWithAdditionalMealTypes,
          hasDietsToSelectMenu: updatedDiet?.hasDietsToSelectMenu,
        })
      );

      return mutateBasketDiet({
        basket: basket['@id'],
        existingItem: existingItem ?? currentDiet?.['@id'] ?? null,
        shouldDebounce,
        ...updatedDiet,
      })
        .then(response => {
          syncGtmCart(basket, response);

          dispatch(setBasketValidity(checkIsValid(response.uiRows)));

          if (!isEmpty(response?.notifications)) {
            const notificationsKeys = Object.keys(
              response?.notifications ?? {}
            );

            const haveInvalidAddons = !isEmpty(
              notificationsKeys.find(key => key.includes('R-ADDON'))
            );

            const haveRemovedDiscountCode = !isEmpty(
              notificationsKeys.find(key =>
                key.includes('DISCOUNT_CODE_REMOVE')
              )
            );

            const haveRemovedAdditionalMealTypes = !isEmpty(
              notificationsKeys.find(
                key =>
                  key.includes('R-DAILY-ITEM') ||
                  key.includes('R-MEAL-TYPE-ITEM')
              )
            );

            if (haveInvalidAddons) {
              handleAddonsChangeUpdate({ existingItem, response });
            }

            if (haveRemovedAdditionalMealTypes) {
              const newAdditionalMealTypes = response?.rows.filter(
                row => row?.['@type'] === 'BasketItemMealType'
              );

              const newAdditionalMealTypesTransformedToRedux =
                newAdditionalMealTypes.reduce((acc, item) => {
                  const { mealType, price, quantity, day } = item ?? {};

                  return {
                    ...acc,
                    [mealType?.['@id']]: {
                      ...acc[mealType?.['@id']],
                      mealTypeName: mealType?.name,
                      mealTypePrice: price?.afterDiscount,
                      deliveryDates: {
                        ...acc[mealType?.['@id']]?.deliveryDates,
                        [day]: quantity,
                      },
                    },
                  };
                }, {});

              dispatch(
                setAdditionalMealTypes({
                  data: newAdditionalMealTypesTransformedToRedux,
                  options: {
                    isOnlyShop,
                    connectedTo: basketActiveOrderDietIri,
                    basketKey,
                  },
                })
              );
            }

            if (haveRemovedDiscountCode) {
              // REDUX
              dispatch(
                setDiscountCode({
                  useDiscountCode: false,
                  discountCode: { '@id': null, value: '' },
                })
              );
              // REACT-QUERY
              queryClient.setQueryData(['discountCode'], {});

              // Sometimes query basket is changed after removing discount and redux basket should be updated
              const transformedResponse = transformResponseBasketRedux(
                response,
                basketItems
              );
              dispatch(setStoreFromQuery(transformedResponse));
            }

            (Object.values(response?.notifications ?? {}) ?? []).forEach(
              ({ message, type }) => {
                showToast(message, { type });
              }
            );
          }
        })
        .catch(error => {
          if (error === ERROR_TYPES.STALE_PROMISE) {
            return;
          }

          dispatch(
            updateDietBasket({
              addons: previousDiet?.addons,
              calorificId: previousDiet?.calorific,
              deliveryDates: sortDates(previousDiet?.deliveryDates ?? []),
              dietId: previousDiet?.diet,
              dietLength: previousDiet?.dietLength,
              existingItem,
              firstDeliveryDay: previousDiet?.firstDeliveryDay,
              name: previousDiet?.name,
              optionChangeMenu: previousDiet?.optionChangeMenu,
              packageId: previousDiet?.package,
              paymentMode: previousDiet?.paymentMode,
              useEcoContainers: previousDiet?.useEcoContainers,
              variantId: previousDiet?.variant,
              sundayInclude: previousDiet?.sundayInclude ?? true,
              saturdayInclude: previousDiet?.saturdayInclude ?? true,
              mealTypes: previousDiet?.mealTypes,
              allowStepWithAdditionalMealTypes:
                previousDiet?.allowStepWithAdditionalMealTypes,
              hasDietsToSelectMenu: previousDiet?.hasDietsToSelectMenu,

              ...(isEmpty(previousDiet?.delivery)
                ? { address: {}, pickUpPoint: {}, deliveryType: 'ADDRESS' }
                : previousDiet?.delivery?.address
                ? {
                    address: previousDiet?.delivery?.address,
                    pickUpPoint: {},
                    deliveryType: 'ADDRESS',
                  }
                : {
                    address: {},
                    pickUpPoint: previousDiet?.delivery?.pickUpPoint,
                    deliveryType: 'PICKUP_POINT',
                  }),
            })
          );

          basketCommonCatch({ data: error?.response?.data });
        });
    },
    [
      basketCommonCatch,
      basketItems,
      basketKey,
      brandName,
      currentDiet,
      disableTracking,
      isBasketStatic,
    ]
  );

  const handleAddonsChangeUpdate = ({ existingItem, response }) => {
    const currentExistingItem = existingItem ?? currentDiet?.['@id'] ?? null;
    const rowInResponse = (response?.rows ?? []).find(
      row => row?.['@id'] === currentExistingItem
    );

    dispatch(
      updateDietBasket({
        addons: rowInResponse?.addons,
      })
    );
  };

  /***************************************
   * START: CHANGE TEST MODE [DIET FULL] *
   ***************************************/

  const {
    data: {
      customDietLengthEnabled,
      days: { standardDays = {}, testDays = {} } = {},
      predefinedDietLengthEnabled,
    } = {},
    refetch: refetchOrderForm,
  } = useOrderForm({ enabled: false });

  const testDaysDefault = testDays?.default;
  const firstTestDayOption = first(testDays?.options);
  const minTestDay = testDays?.min;
  const standardDaysDefault = standardDays?.default;
  const predefinedDaysDefault = (standardDays?.options ?? [])?.[0];

  const handleChangeTestMode = useCallback(
    ({
      basketId,
      isEnabled,
      isEnabledUpdateBasket = true,
      deliveryDates = [],
      ignoreStaticBasket = false,
      customDietLength,
    }) => {
      if (isBasketStatic && !ignoreStaticBasket) {
        const dietLength = isEnabled
          ? testDaysDefault ?? firstTestDayOption
          : standardDaysDefault;

        const basketPrices = getBasketPrices({
          dietElements: basketStore.items.dietElements,
          testMode: isEnabled,
          queryClient,
        });

        return dispatch(
          updateBasketWithCurrentDiet({
            testMode: isEnabled,
            currentDiet: {
              deliveryDates: sortDates(deliveryDates ?? []),
              dietLength,
              price: basketPrices?.price,
            },
          })
        );
      }

      return updateOrderBasketModify({
        basketId,
        payload: { testMode: isEnabled },
      }).then(() => {
        if (isEnabledUpdateBasket) {
          const dietLength = isEnabled
            ? testDaysDefault ?? firstTestDayOption ?? minTestDay
            : predefinedDietLengthEnabled && !customDietLengthEnabled
            ? predefinedDaysDefault ?? standardDaysDefault
            : standardDaysDefault;

          handleUpdateBasketDiet({
            dietLength: customDietLength ?? dietLength,
            deliveryDates,
          });
        }
      });
    },
    [
      basketMode,
      firstTestDayOption,
      handleUpdateBasketDiet,
      isBasketStatic,
      minTestDay,
      standardDaysDefault,
      testDaysDefault,
      updateOrderBasketModify,
      customDietLengthEnabled,
      predefinedDietLengthEnabled,
    ]
  );

  /**************************************
   * END: CHANGE TEST MODE [DIET FULL] *
   *************************************/

  const handleBasketClear = useCallback(
    async ({ basketId, disableToast = false } = {}) => {
      const basket = getBasketQueryData();
      if (basketId || basketQueryId) {
        return basketClear({
          id: basketId || basketQueryId,
          disableToast,
        }).then(response => {
          syncGtmCart(basket, response, { asSeparateEvents: false });
          dispatch(setBasketValidity(checkIsValid(response.uiRows)));
        });
      }
    },
    [basketQueryId, brandName, disableTracking]
  );

  // clear for static basket
  const [getStaticBasketStore] = useStaticBasketStore();

  const setBasketAbandoned = async (basketId, basketKey) => {
    const basket = getBasketQueryData();
    try {
      const response = await updateBasketModify({
        id: basketId,
        payload: { abandoned: true },
      });

      syncGtmCart(basket, {}, { asSeparateEvents: false });

      queryClient.setQueryData([basketKey], {});

      return response;
    } catch (error) {
      return error;
    }
  };

  const handleClickClearStaticBasket = useCallback(
    async (basketId = basketQueryId, disableToast = false) => {
      if (!isDiet || isBasketEditOrder) {
        return handleBasketClear({});
      }

      // default settings
      const noApiBasketStore = getStaticBasketStore();

      if (!disableToast) {
        showToast(
          t(
            '$*notification.basketClear.clear.success',
            'Koszyk został poprawnie wyczyszczony.'
          ),
          { type: 'success' }
        );
      }

      if (basketId) {
        await setBasketAbandoned(basketId, basketKey);
      }

      setTransformBasketStatus('NORMAL_TO_STATIC');

      dispatch(changeStepAndResetValid(0));
      return dispatch(setStoreFromQuery(noApiBasketStore));
    },
    [basketQueryId, getStaticBasketStore, handleBasketClear]
  );

  const initBasket = useCallback(
    async ({
      basketId,
      createFromLatestDiet = false,
      ecommerceDiet = null,
      forceCreate = false,
      noBasketMode = false,
      oldBasketParams = {},
      withDefaultDiet = false,
      isSyncGtmCartDisabled = false,
    } = {}) => {
      const persistedBasket = getBasketQueryData();

      if (noBasketMode && isDiet) {
        if (oldBasketParams && basketQueryId) {
          setTransformBasketStatus('INIT_NORMAL_TO_STATIC');
          await setBasketAbandoned(basketQueryId, basketKey);
        }

        const noApiBasketStore = getStaticBasketStore(oldBasketParams);

        return dispatch(setStoreFromQuery(noApiBasketStore));
      }

      if (ecommerceDiet || createFromLatestDiet) {
        setIsCompletedCreateBasketParams(false);

        return createBasket({
          ...(ecommerceDiet ? { ecommerceDiet } : {}),
          createFromLatestDiet,
          withDefaultDiet,
        }).then(response => {
          if (!isSyncGtmCartDisabled) {
            syncGtmCart({}, response);
          }

          const transformedResponse = transformResponseBasketRedux(
            response,
            basketItems
          );
          dispatch(setStoreFromQuery(transformedResponse));
          dispatch(setBasketValidity(checkIsValid(response.uiRows)));

          setIsCompletedCreateBasketParams(true);

          return response;
        });
      }

      if (forceCreate || basketId) {
        setIsCompletedCreateBasketParams(false);

        return createBasket({ basketId }).then(async response => {
          if (!isEmpty(basketId) && isEmpty(response?.rows)) {
            return await handleClickClearStaticBasket(response.id);
          }

          const transformedResponse = transformResponseBasketRedux(
            response,
            basketItems
          );

          dispatch(setStoreFromQuery(transformedResponse));
          dispatch(setBasketValidity(checkIsValid(response.uiRows)));

          setIsCompletedCreateBasketParams(true);

          return response;
        });
      }

      if (!isFalsify(persistedBasket) && !isPayActionSuccess) {
        return basketCleanUp({ id: persistedBasket?.id });
      }

      return createBasket({ withDefaultDiet }).then(response => {
        const transformedResponse = transformResponseBasketRedux(
          response,
          basketItems
        );
        dispatch(setStoreFromQuery(transformedResponse));
        dispatch(setBasketValidity(checkIsValid(response.uiRows)));

        return response;
      });
    },
    [basketKey, isPayActionSuccess, getStaticBasketStore]
  );

  const createBasketFromStaticBasket = useCallback(
    async overrideDiet => {
      const basket = getBasketQueryData();

      setTransformBasketStatus('STATIC_TO_NORMAL');

      const response = await createBasket();

      return mutateBasketDiet({
        basket: response['@id'],
        package: firstDietElement.packageId,
        diet: firstDietElement.dietId,
        variant: firstDietElement.variantId,
        calorific: firstDietElement.calorificId,
        paymentMode: firstDietElement.paymentMode,
        dietLength: firstDietElement.dietLength,
        deliveryDates: firstDietElement.deliveryDates,
        firstDeliveryDay: firstDietElement.firstDeliveryDay,
        useEcoContainers: firstDietElement.useEcoContainers,
        addons: firstDietElement.addons,
        optionChangeMenu: firstDietElement.optionChangeMenu,
        saturdayInclude: firstDietElement.saturdayInclude,
        sundayInclude: firstDietElement.sundayInclude,
        mealTypes: firstDietElement.mealTypes,
        allowStepWithAdditionalMealTypes:
          firstDietElement.allowStepWithAdditionalMealTypes,
        hasDietsToSelectMenu: firstDietElement?.hasDietsToSelectMenu,
        ...overrideDiet,
      })
        .then(response => {
          syncGtmCart(basket, response, { withAddedDiet: false });

          setFailureCount(0);

          const firstResponseDiet = getNewDietFromBasketResponse(response);

          dispatch(setBasketMode(BASKET_MODES.NORMAL));

          dispatch(
            updateDietBasket({
              '@id': firstResponseDiet?.['@id'],
              '@type': firstResponseDiet?.['@type'],
              ...overrideDiet,
            })
          );

          if (isBasketStatic && basketStore?.testMode) {
            handleChangeTestMode({
              isEnabled: true,
              basketId: response?.id,
              ignoreStaticBasket: true,
              deliveryDates: firstDietElement.deliveryDates,
              customDietLength: firstDietElement.deliveryDates.length,
            });
          }

          dispatch(setBasketValidity(checkIsValid(response.uiRows)));

          return response;
        })
        .catch(error => {
          setFailureCount(failureCount + 1);

          const isCancelledError =
            error && Object.prototype.hasOwnProperty.call(error, 'silent');

          if (!isCancelledError) {
            basketCommonCatch({
              data: error?.response?.data,
              passedBasketQueryId: response['@id'],
              isInitializingError: true,
            });
          }
        });
    },
    [basketCommonCatch, firstDietElement]
  );

  const handleAddAdditionalMealTypes = useCallback(
    async data => {
      const { mealTypeName, mealTypePrice, connectedToIri, ...restProps } =
        data ?? {};
      let basketIri;
      let connectedTo = connectedToIri ?? basketActiveOrderDietIri;

      if (isBasketStatic) {
        const response = await createBasketFromStaticBasket();
        const newDietInBasket = getNewDietFromBasketResponse(response);

        basketIri = response?.['@id'];
        connectedTo = newDietInBasket?.['@id'];
      }

      const previousAdditionalMealTypes =
        currentDiet?.connectedElements?.additionalMealTypes;

      dispatch(
        updateAdditionalMealTypes({
          item: {
            mealTypeName,
            mealTypePrice,
            dates: restProps?.days,
            quantity: restProps?.quantity,
            mealTypeIri: restProps?.mealType,
          },
          options: {
            isOnlyShop,
            connectedTo,
            basketKey,
          },
          shouldForceRange: restProps.shouldForceRange,
        })
      );

      return mutateAdditionalMealTypes({
        ...restProps,
        basket: basketIri ?? basketQuery?.data?.['@id'],
        connectedTo,
      })
        .then(response => {
          dispatch(setBasketValidity(checkIsValid(response.uiRows)));
        })
        .catch(error => {
          dispatch(
            setAdditionalMealTypes({
              data: previousAdditionalMealTypes,
              options: {
                isOnlyShop,
                connectedTo,
                basketKey,
              },
            })
          );
          basketCommonCatch({ data: error?.response?.data });
        });
    },

    [
      basketKey,
      brandName,
      currentDiet,
      basketItems,
      currencyCode,
      basketQueryId,
      isBasketStatic,
      disableTracking,
      firstDietElement,
      basketCommonCatch,
      basketActiveOrderDietIri,
    ]
  );

  const handleClickShareStaticBasket = useCallback(async () => {
    const response = await createBasketFromStaticBasket();
    await shareBasket(response.id);
  }, [createBasketFromStaticBasket, shareBasket]);

  const duplicateBasketDiet = useCallback(
    async basketItemDiet => {
      const basket = getBasketQueryData();

      try {
        const response = await mutateBasketDietDuplicate({
          shouldDebounce: true,
          basketItemDiet,
        });

        syncGtmCart(basket, response, { asSeparateEvents: false });

        setFailureCount(0);

        const transformedResponse = transformResponseBasketRedux(
          response,
          basketItems
        );

        const lastAddedDiet =
          transformedResponse?.items?.dietElements[
            transformedResponse?.items?.dietElements?.length - 1
          ];

        dispatch(setStoreFromQuery(transformedResponse));
        dispatch(setActiveOrderDietIri(lastAddedDiet?.['@id']));
        dispatch(setBasketValidity(checkIsValid(response.uiRows)));
      } catch (error) {
        if (error === ERROR_TYPES.STALE_PROMISE) {
          return;
        }

        setFailureCount(failureCount + 1);

        const isCancelledError =
          error && Object.prototype.hasOwnProperty.call(error, 'silent');

        if (!isCancelledError) {
          basketCommonCatch(error?.response?.data);
        }
      }
    },
    [basketCommonCatch]
  );

  const { isSubscriptionInCart, basketDeliveryType } = useMemo(() => {
    return {
      isSubscriptionInCart:
        basketQuery?.data?.rows?.some(
          ({ paymentMode }) =>
            paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT
        ) ?? false,
      basketDeliveryType: basketQuery?.data?.defaultPickUpPoint
        ? 'pickUpPoint'
        : 'address',
    };
  }, [basketQuery.dataUpdatedAt, basketQuery.errorUpdatedAt]);

  const testMode = useMemo(() => {
    if (isBasketStatic) return basketStore.testMode;
    return basketQuery?.data?.testMode ?? false;
  }, [basketStore.testMode, basketQuery?.data?.testMode, isBasketStatic]);

  const value = {
    ...basketActions,
    ...stateBasket,
    basketCommonCatch,
    basketDeliveryType,
    basketKey,
    basketQuery,
    basketStore,
    checkBasketIsValidToPayment,
    checkIsAddonSelected,
    checkIsProductSelected,
    commonSetDiscountCode,
    currentDiet,
    handleAddBasketDiet,
    handleBasketClear,
    handleChangeAddonQuantity,
    handleChangeProductQuantity,
    handleChangeTestMode,
    handleClickClearStaticBasket,
    handleClickShareStaticBasket,
    handleRemoveBasketDiet,
    handleUpdateBasketDiet,
    handleUpdateBasketModifyDay,
    initBasket,
    isBasketEditOrder,
    isBasketNewOrder,
    isBasketStatic,
    isCompletedCreateBasketParams,
    isEmptyBasket,
    isFetchingBasket,
    isMutatingBasket,
    isSubscriptionInCart,
    recheckDiscountCode,
    setShowAbandonedModal,
    setTransformBasketStatus,
    shareBasket,
    showAbandonedModal,
    testMode,
    duplicateBasketDiet,
    transformBasketStatus,
    updateBasketModifyQuery,
    mutateDietQuery,
    updateOrderBasketModify,
    validateDiscountCodeQuery,
    shouldScrollIntoVariants,
    setShouldScrollIntoVariants,
    handleAddAdditionalMealTypes,
    handleChangeMealTypesQuantity,
    createBasketFromStaticBasket,
  };

  return (
    <BasketMethodsContext.Provider value={value}>
      {children}
    </BasketMethodsContext.Provider>
  );
};

export default BasketMethodsProvider;
