import dynamic from 'next/dynamic';

import {
  type ElementType,
  type MouseEvent,
  type ReactNode,
  type Ref,
  forwardRef,
} from 'react';
import { type SerializedStyles, css } from '@emotion/react';
import tw, { type TwStyle } from 'twin.macro';

const SpinnerThirdIcon = dynamic(
  () => import('@assets/icons/SpinnerThirdIcon')
);

type Variant = 'contained' | 'outlined' | 'text' | 'link';
type ComponentMouseEvent = MouseEvent<HTMLButtonElement, MouseEvent>;
type Style = TwStyle | (TwStyle | false)[];

type Props = {
  children?: ReactNode;
  Component?: ElementType;
  disabled?: boolean;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  isLoading?: boolean;
  startIcon?: ReactNode;
  tooltip?: string;
  type?: 'button' | 'reset' | 'submit';
  variant?: Variant;
  color?: 'primary' | 'default' | 'success' | 'warning' | 'error';
  size?: 'sm' | 'base' | 'lg';
  styles?: {
    button?: Style;
    spinner?: Style;
  };
  noHover?: boolean;
  onClick?: (e: ComponentMouseEvent) => void;
};

const Button = forwardRef(
  (
    {
      children,
      Component = 'button',
      disabled = false,
      endIcon: endIconProp,
      fullWidth = false,
      isLoading = false,
      startIcon: startIconProp,
      type = 'button',
      variant = 'contained',
      color = 'primary',
      size = 'base',
      styles = {},
      noHover = false,
      onClick = () => {},
      ...restProps
    }: Props,
    ref: Ref<HTMLButtonElement>
  ) => {
    const isDisabled = disabled || isLoading;

    const startIcon = startIconProp && (
      <span tw="flex items-center mr-4">{startIconProp}</span>
    );

    const endIcon = endIconProp && (
      <span tw="flex items-center ml-4">{endIconProp}</span>
    );

    const primaryText = css`
      ${tw`transition-colors duration-300 text-primary`};
      ${!isDisabled && !noHover
        ? tw`focus-visible:(ring-primary-hover text-primary-hover) hover:(text-primary-hover transition-colors duration-300)`
        : null};
      ${variant === 'link' && tw`focus-visible:(rounded-sm)`}
    `;

    const variantStyle: {
      [key in Variant]: {
        [key: string]: {
          [key: string]: SerializedStyles | TwStyle | null | false;
        };
      };
    } = {
      contained: {
        primary: {
          button: css`
            ${tw`text-white bg-primary border-primary`};
            ${!isDisabled && !noHover && !isLoading
              ? tw`hover-hover:hover:(bg-primary-hover border-primary-hover)`
              : tw`cursor-default`};
          `,
        },
        default: {
          button: css`
            ${tw`text-white bg-gray-2 border-gray-2`};
            ${!isDisabled && !noHover && tw`hover:(bg-gray-2 border-gray-2)`};
          `,
        },
        success: {
          button: css`
            ${tw`text-white bg-green-1 border-green-1`};
            ${!isDisabled && !noHover && tw`hover:(bg-green-1 border-green-1)`};
          `,
        },
        warning: {
          button: css`
            ${tw`text-white bg-yellow-0 border-yellow-0`};
            ${!isDisabled &&
            !noHover &&
            tw`hover:(bg-yellow-0 border-yellow-0)`};
          `,
        },
        error: {
          button: css`
            ${tw`text-white bg-red-2 border-red-2`};
            ${!isDisabled && !noHover && tw`hover:(bg-red-2 border-red-2)`};
          `,
        },
      },
      outlined: {
        primary: {
          button: css`
            ${tw`border-primary text-primary`};
            ${!isDisabled && !noHover && tw`hover:(bg-primary text-white)`}
          `,
          spinner: isDisabled && tw`text-primary`,
        },
        default: {
          button: css`
            ${tw`text-gray-2 border-gray-2`};
            ${!isDisabled && !noHover && tw` hover:(bg-gray-2 text-white)`};
          `,
        },
        success: {
          button: css`
            ${tw`text-green-1 border-green-1`};
            ${!isDisabled && !noHover && tw`hover:(bg-green-1 text-white)`}
          `,
        },
        warning: {
          button: css`
            ${tw`text-yellow-0 border-yellow-0`};
            ${!isDisabled && !noHover && tw`hover:(bg-yellow-0 text-white)`}
          `,
        },
        error: {
          button: css`
            ${tw`text-red-2 border-red-2`};
            ${!isDisabled && !noHover && tw`hover:(bg-red-2 text-white)`}
          `,
        },
      },
      text: {
        error: {
          button: css`
            ${tw`text-red-1`};
            ${!isDisabled && !noHover && tw`hover:(text-red-1)`};
          `,
          spinner: null,
        },
        primary: {
          button: primaryText,
          spinner: null,
        },
      },
      link: {
        primary: {
          button: primaryText,
          spinner: null,
        },
        error: {
          button: css`
            ${tw`text-red-2 hover:(text-red-2 underline)`};
            ${isLoading && tw`flex items-center`}
          `,
          spinner: tw`text-red-1`,
        },
      },
    };

    const paddingStyle = {
      sm: tw`px-4 py-2`,
      base: tw`px-6 py-3`,
      lg: tw`px-8 py-4`,
    };

    const twButtonStyle = [
      variant !== 'link' && [
        paddingStyle[size],
        tw`text-sm font-bold text-center rounded-md align-middle duration-300 border border-transparent cursor-pointer select-none focus-visible:(ring-2 outline-none ring-offset-2) hover:(transition-colors duration-300)`,
      ],
      tw`flex items-center tracking-wide`,
      fullWidth && tw`justify-center w-full`,
      variantStyle[variant][color]?.button,
      isDisabled && [tw`cursor-not-allowed opacity-70`],
      styles?.button,
    ];

    const twSpinnerStyle = [
      tw`w-5 text-white animate-spin`,
      !!children && tw`mr-3 -ml-1`,
      variantStyle[variant][color]?.spinner,
      styles?.spinner,
    ];

    return (
      <Component
        css={twButtonStyle}
        disabled={isDisabled}
        ref={ref}
        type={type}
        onClick={(e: ComponentMouseEvent) => {
          if (isDisabled) {
            return;
          }

          onClick(e);
        }}
        {...restProps}
      >
        {isLoading && <SpinnerThirdIcon css={twSpinnerStyle} />}

        {startIcon}
        {children}
        {endIcon}
      </Component>
    );
  }
);

Button.displayName = 'Button';

export default Button;
