import { useQuery, useQueryClient } from '@tanstack/react-query';

type Basket = {
  '@id': string;
  '@type': 'Basket';
  abandoned: boolean;
  availableBonuses: { [key: string]: unknown };
  createdAt: string;
  defaultAddress: { [key: string]: unknown };
  defaultPickUpPoint: null;
  discountCode: null;
  id: string;
  invoiceType: string;
  notifications: unknown[];
  paymentMetadata: { [key: string]: unknown };
  paymentType: string;
  paymentTypes: string[];
  price: { [key: string]: unknown };
  rows: { [key: string]: unknown }[];
  testMode: boolean;
  uiRows: { [key: string]: unknown };
  useMoneybox: boolean;
};

const useBasket = ({ basketKey }: { basketKey: string }) => {
  const queryClient = useQueryClient();

  const query = useQuery<Basket | { [key: string]: never }>({
    queryKey: [basketKey],
    queryFn: () => queryClient.getQueryData<Basket>([basketKey]) || {},
  });

  return {
    ...query,
    data: query?.data ?? {},
  };
};

export default useBasket;
