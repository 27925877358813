import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { type ReactNode, isValidElement, useEffect, useState } from 'react';
import type { PaymentType as PaymentTypeTs } from '@tsTypes/PaymentType';
import type { FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import PAYMENT_TYPES from '@constants/paymentTypes';
import useBasket from '@hooks/useBasket';
import usePaymentCards from '@hooks/usePaymentCards';
import checkIfPaymentTypesIncludesCard from '@utils/checkIfPaymentTypesIncludesCard';

import PaymentType from './PaymentType';
import PaymentTypesReorderPage from './PaymentTypesReorderPage';
import usePaymentTypeProps from './usePaymentTypeProps';

const PayPoModal = dynamic(() => import('./PayPoModal/PayPoModal'));
const CardModal = dynamic(() => import('./CardModal'));

type AdditionalMetadataPaymentTypes =
  (typeof additionalMetadataPaymentTypes)[number];

const additionalMetadataPaymentTypes = [
  PAYMENT_TYPES.PAYU_CARD,
  PAYMENT_TYPES.STRIPE_CARD,
  PAYMENT_TYPES.PAYPO,
  PAYMENT_TYPES.PAYU_PAYPO,
] as const;

type Metadata = {
  paypoAddressPostCode: string;
  paypoAddressCity: string;
  paypoAddressStreet: string;
  paypoAddressBuildNumber: string;
  paypoAddressPlaceNumber: string;
};

type PaymentTypesProps = {
  onClickConfirmBlueMediaBlik?: () => void;
  onClickConfirmPayuBlik?: () => void;
  onClickPayment?: ({
    paymentType,
    metadata,
  }: {
    paymentType: PaymentTypeTs;
    metadata?: Metadata;
  }) => void;
  payments?: PaymentTypeTs[];
  selectedPaymentType?: PaymentTypeTs | null;
  blikCode?: string | undefined;
  setBlikCode?: () => void | undefined;
  isBlikExternalMode?: boolean;
  isSelectMode?: boolean;
};

const PaymentTypes = ({
  onClickConfirmBlueMediaBlik = () => {},
  onClickConfirmPayuBlik = () => {},
  onClickPayment = () => {},
  payments = [],
  selectedPaymentType = null,
  blikCode,
  setBlikCode,
  isBlikExternalMode = false,
  isSelectMode = false,
}: PaymentTypesProps) => {
  const { t } = useTranslation();

  const { data: cards = [] } = usePaymentCards({
    enabled: checkIfPaymentTypesIncludesCard(payments),
  });

  const { data: basket } = useBasket({
    basketKey: 'basketNewOrder',
  });

  const [modalDemandingPaymentType, setModalDemandingPaymentType] = useState<
    PaymentTypeTs | ''
  >('');

  const [isStripeCardModalOpen, setIsStripeCardModalOpen] = useState(false);

  const openStripeCardModal = () => {
    setIsStripeCardModalOpen(true);
  };

  const closeStripeCardModal = () => {
    setIsStripeCardModalOpen(false);
    setModalDemandingPaymentType('');
  };

  const [isPayUCardModalOpen, setIsPayUCardModalOpen] = useState(false);

  const openPayUCardModal = () => {
    setIsPayUCardModalOpen(true);
  };

  const closePayUCardModal = () => {
    setIsPayUCardModalOpen(false);
    setModalDemandingPaymentType('');
  };

  const [isPayPoModalOpen, setIsPayPoModalOpen] = useState<boolean | null>(
    null
  );

  const openPayPoModal = () => {
    setIsPayPoModalOpen(true);
  };

  const closePayPoModal = () => {
    setIsPayPoModalOpen(false);
    setModalDemandingPaymentType('');
  };

  const isSelectedProviderCardAvailable = !isEmpty(
    cards.find(({ provider }) => provider === selectedPaymentType)
  );

  useEffect(() => {
    if (selectedPaymentType !== null && !isSelectedProviderCardAvailable) {
      switch (selectedPaymentType) {
        case PAYMENT_TYPES.STRIPE_CARD:
          openStripeCardModal();
          break;
        case PAYMENT_TYPES.PAYU_CARD:
          openPayUCardModal();
          break;
        default:
          break;
      }
    }
  }, []);

  const paymentTypeProps = usePaymentTypeProps({
    blikCode,
    setBlikCode,
    selectedPaymentType,
    onClickPayment,
    isBlikExternalMode,
  });

  const sortPaymentTypes = (paymentTypes: PaymentTypeTs[]) => {
    const paymentsOrder = [
      PAYMENT_TYPES.PAYU_BLIK,
      PAYMENT_TYPES.PAYU_CARD,
      PAYMENT_TYPES.PAYU,
      PAYMENT_TYPES.BLUE_MEDIA_BLIK,
      PAYMENT_TYPES.BLUE_MEDIA_CARD,
      PAYMENT_TYPES.BLUE_MEDIA,
      PAYMENT_TYPES.PAYNOW,
      PAYMENT_TYPES.TPAY,
      PAYMENT_TYPES.PAYPO,
      PAYMENT_TYPES.PAYU_PAYPO,
      PAYMENT_TYPES.STRIPE_LINK,
      PAYMENT_TYPES.STRIPE_CARD,
      PAYMENT_TYPES.BANK_WIRE,
      PAYMENT_TYPES.CASH,
    ];

    return paymentsOrder.filter(type => paymentTypes.includes(type));
  };

  const sortedPaymentTypes = sortPaymentTypes(payments);

  const handleClickPayment = (paymentType: PaymentTypeTs) => {
    if (paymentType === PAYMENT_TYPES.BLUE_MEDIA_BLIK && isBlikExternalMode) {
      return onClickConfirmBlueMediaBlik();
    }

    if (paymentType === PAYMENT_TYPES.PAYU_BLIK && isBlikExternalMode) {
      return onClickConfirmPayuBlik();
    }

    if (
      (paymentType === PAYMENT_TYPES.STRIPE_CARD &&
        isEmpty(
          cards.find(card => card.provider === PAYMENT_TYPES.STRIPE_CARD)
        )) ||
      (paymentType === PAYMENT_TYPES.PAYU_CARD &&
        isEmpty(
          cards.find(card => card.provider === PAYMENT_TYPES.PAYU_CARD)
        )) ||
      PAYMENT_TYPES.PAYPO === paymentType ||
      PAYMENT_TYPES.PAYU_PAYPO === paymentType
    ) {
      setModalDemandingPaymentType(paymentType);

      return;
    }

    return onClickPayment({ paymentType });
  };

  useEffect(() => {
    if (
      PAYMENT_TYPES.PAYPO === modalDemandingPaymentType ||
      PAYMENT_TYPES.PAYU_PAYPO === modalDemandingPaymentType
    ) {
      openPayPoModal();
      return;
    }
    if (
      modalDemandingPaymentType === PAYMENT_TYPES.PAYU_CARD &&
      isEmpty(cards.find(card => card.provider === PAYMENT_TYPES.PAYU_CARD))
    ) {
      openPayUCardModal();
      return;
    }

    if (
      modalDemandingPaymentType === PAYMENT_TYPES.STRIPE_CARD &&
      isEmpty(cards.find(card => card?.provider === PAYMENT_TYPES.STRIPE_CARD))
    ) {
      openStripeCardModal();
    }
  }, [modalDemandingPaymentType]);

  useEffect(() => {
    if (selectedPaymentType) {
      setModalDemandingPaymentType(selectedPaymentType);
    }
  }, [selectedPaymentType]);

  const handleClickConfirmStripeCard = () => {
    setIsStripeCardModalOpen(false);
    onClickPayment({ paymentType: PAYMENT_TYPES.STRIPE_CARD });
  };

  const handleClickConfirmPayUCard = () => {
    setIsPayUCardModalOpen(false);
    onClickPayment({ paymentType: PAYMENT_TYPES.PAYU_CARD });
  };

  const handleClickConfirmPaymentPayPo = (
    metadata: Metadata,
    { setSubmitting }: FormikHelpers<Metadata>
  ) => {
    setIsPayPoModalOpen(false);
    setSubmitting(false);
    if (modalDemandingPaymentType) {
      onClickPayment({ paymentType: modalDemandingPaymentType, metadata });
    }
  };

  if (isEmpty(payments)) {
    return null;
  }

  const hasPaymentType = (paymentType: PaymentTypeTs[]) =>
    payments.some(type => paymentType.includes(type));
  const hasPayPoPayment = hasPaymentType([
    PAYMENT_TYPES.PAYPO,
    PAYMENT_TYPES.PAYU_PAYPO,
  ]);
  const hasPayUCardPayment = hasPaymentType([PAYMENT_TYPES.PAYU_CARD]);
  const hasStripeCardPayment = hasPaymentType([PAYMENT_TYPES.STRIPE_CARD]);

  const formatOptionLabel = ({
    value,
    isSelected,
  }: {
    value: PaymentTypeTs;
    isSelected?: boolean | undefined;
  }) => {
    const typeComponents = paymentTypeProps[value];

    if (
      PAYMENT_TYPES.STRIPE_CARD === value ||
      PAYMENT_TYPES.PAYU_CARD === value
    ) {
      const typeComponents = paymentTypeProps[value];

      if (typeComponents?.component) {
        return typeComponents.component(
          selectedPaymentType === value,
          isSelectMode
        );
      }
    }

    return (
      <div
        tw="relative flex flex-col flex-wrap"
        css={isSelected && typeComponents?.content && tw`py-3`}
      >
        <div tw="flex items-center">
          <div tw="w-14 mr-2 flex justify-center">{typeComponents?.image}</div>
          <div>{typeComponents?.text}</div>
        </div>
        {isSelected && (
          <div tw="ml-1" style={{ width: '95%' }}>
            {typeComponents?.content}
          </div>
        )}
      </div>
    );
  };

  if (
    (isEmpty(sortedPaymentTypes) && !isEmpty(payments)) ||
    (typeof basket?.price?.afterDiscount === 'number' &&
      basket.price.afterDiscount <= 0)
  ) {
    return null;
  }

  const additionalMetadataPayment: AdditionalMetadataPaymentTypes[] = [
    PAYMENT_TYPES.PAYU_CARD,
    PAYMENT_TYPES.PAYU_PAYPO,
    PAYMENT_TYPES.PAYPO,
    PAYMENT_TYPES.STRIPE_CARD,
  ];
  const isPaymentMetadataMissing =
    additionalMetadataPayment.some(type =>
      additionalMetadataPaymentTypes.includes(type)
    ) && !modalDemandingPaymentType;

  return (
    <>
      <div tw="mb-4">
        {isSelectMode ? (
          <PaymentTypesReorderPage
            sortedPaymentTypes={sortedPaymentTypes}
            selectedPaymentType={selectedPaymentType}
            handleClickPayment={handleClickPayment}
            formatOptionLabel={formatOptionLabel}
          />
        ) : (
          <div data-cy="payment-types">
            {!isEmpty(sortedPaymentTypes) ? (
              sortedPaymentTypes.map((paymentType, index) => {
                const typeComponents = paymentTypeProps[paymentType];
                const handleClickPaymentItem = () => {
                  handleClickPayment(paymentType);
                };

                if (typeof typeComponents?.component === 'function') {
                  return typeComponents.component(
                    selectedPaymentType === paymentType,
                    isSelectMode
                  );
                }
                return (
                  <PaymentTypeItem
                    key={index}
                    onClick={() => {
                      handleClickPaymentItem();
                    }}
                    isSelected={
                      selectedPaymentType === paymentType &&
                      (selectedPaymentType === PAYMENT_TYPES.PAYU_CARD ||
                      selectedPaymentType === PAYMENT_TYPES.STRIPE_CARD
                        ? isSelectedProviderCardAvailable
                        : true)
                    }
                    type={paymentType}
                    text={typeComponents?.text || ''}
                    {...typeComponents}
                  />
                );
              })
            ) : (
              <span>{t('$*components.paymentTypes.noPaymentTypes')}</span>
            )}
          </div>
        )}
        {hasStripeCardPayment && (
          <CardModal
            paymentType={PAYMENT_TYPES.STRIPE_CARD}
            isOpen={isStripeCardModalOpen}
            closeModal={closeStripeCardModal}
            onClickConfirm={handleClickConfirmStripeCard}
          />
        )}
        {hasPayUCardPayment && (
          <CardModal
            paymentType={PAYMENT_TYPES.PAYU_CARD}
            isOpen={isPayUCardModalOpen}
            closeModal={closePayUCardModal}
            onClickConfirm={handleClickConfirmPayUCard}
          />
        )}
        {hasPayPoPayment && (
          <PayPoModal
            isOpen={isPayPoModalOpen}
            closeModal={closePayPoModal}
            onClickConfirm={handleClickConfirmPaymentPayPo}
          />
        )}
      </div>
      <p
        tw="text-sm text-red-1 py-0 my-0 mb-1"
        aria-hidden={!isPaymentMetadataMissing}
        css={isPaymentMetadataMissing ? tw`visible` : tw`invisible`}
      >
        {t('$*reorderPage.payment.missingDataWarning')}
      </p>
    </>
  );
};

const PaymentTypeItem = ({
  image,
  text,
  rightCol,
  content,
  type,
  onClick,
  isSelected,
}: {
  image?: ReactNode;
  text: string;
  rightCol?: ReactNode;
  content?: ReactNode;
  type?: PaymentTypeTs;
  onClick: () => void;
  isSelected: boolean;
}) => {
  return (
    <PaymentType type={type} onClick={onClick} isSelected={isSelected}>
      {image && isValidElement(image) && (
        <PaymentType.Image size={'lg'}>{image}</PaymentType.Image>
      )}
      <PaymentType.Text>{text}</PaymentType.Text>
      {rightCol && isValidElement(rightCol) && (
        <PaymentType.RightCol>{rightCol}</PaymentType.RightCol>
      )}
      {content && isValidElement(content) && (
        <PaymentType.Content>{content}</PaymentType.Content>
      )}
    </PaymentType>
  );
};

PaymentTypeItem.displayName = 'PaymentTypeItem';
PaymentTypes.displayName = 'PaymentTypes';

export default PaymentTypes;
